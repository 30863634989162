import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Menu,
  Paper,
  Dialog,
  Typography,
  TextField,
  Select,
  FormControl,
  Grid,
  Avatar,
  Popover,
  Divider,
  Checkbox,
} from "@material-ui/core";
import "./topbar.css";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { NavLink } from "react-router-dom";
import { UserContext } from "src/context/User";
import { GrCart } from "react-icons/gr";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CiSearch } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import clsx from "clsx";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { KeyboardArrowDown } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { menuProps } from "src/utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AuthContext } from "src/context/Auth";
import { apiConfig } from "src/apiconfig/ApiConfig";
import axios from "axios";
import { toast } from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import CommonPopup from "src/views/pages/Dashboard/Watchlist/CommonPopup";
import HeaderSearch from "../../component/HeaderSearch";
import { red } from "@material-ui/core/colors";
const headersData = [
  {
    label: "Blockchain",
    href: process.env.REACT_APP_GYAN_CHAIN,
    img: "/images/blockchain.png",
  },
  {
    label: "About Us",
    href: "/about-us",
    img: "/images/Shape.png",
  },
  {
    label: "Resource Center",
    href: "/resource-center",
    img: "/images/rccenter.png",
  },
  {
    label: "Catlog",
    href: "/catalouge",
    img: "/images/catlog.png",
  },
  {
    label: "Media",
    href: "/media",
    img: "/images/media.png",
  },
  {
    label: "Lite Paper",
    href: "/lite-paper",
    img: "/images/litepaper.png",
  },
  {
    label: "FAQ",
    href: "/faq",
    img: "/images/faq.png",
  },
];

// const dropsData = [
//   {
//     label: "Featured",
//     href: "/about-coe-program",
//     img: "/images/featured.png",
//   },
//   {
//     label: "Partners",
//     href: "/doctors-page",
//     img: "/images/partner.png",
//   },
// ];

const AICEO = [
  {
    label: "About I & AI CoEs",
    href: "/about-coe-program",
    img: "/images/existingPartner.png",
  },

  {
    label: "COE Partners",
    href: "/coe-partners",
    img: "/images/existingPartner.png",
  },
  // {
  //   label: "I & AI CoEs",
  //   href: "/coes",
  //   img: "/images/existingPartner.png",
  // },
];

const statsData = [
  {
    label: "Ranking",
    href: "/ranking",
    img: "/images/rank.png",
  },
  {
    label: "Activity",
    href: "/activity",
    img: "/images/activity.png",
  },
];

const langData = [
  {
    label: "English",
    href: "/about-coe-program",
  },
  {
    label: "Hindi",
    href: "/doctors-page",
  },
  {
    label: "Bengali",
    href: "/about-coe-program",
  },
  {
    label: "Urdu",
    href: "/doctors-page",
  },
  {
    label: "Punjabi",
    href: "/doctors-page",
  },

  {
    label: "Malayalam",
    href: "/doctors-page",
  },
];

const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiMenuItem-root": {
      display: "flex",
      alignItems: "center !important",
    },
  },

  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#636880",
    padding: "0px 20px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: theme.palette.text.primary,
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  appBar: {
    "& .MuiContainer-root": {
      padding: "0px 40px !important",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "16px  !important",
        paddingRight: "4px  !important",
      },

      "@media (min-width: 1280px)": {
        paddingLeft: "40px",
        paddingRight: "40px",
      },
    },
  },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    padding: " 8px 0px",
    "& .MuiAvatar-root":{
      // background:'green',
      // border:'2px solid #B158E7',
      overflow:"visible",
      "& img":{
        overflow:'hidden',
        borderRadius:'50%'
      },
    },

    "& .userProfileTypeIcon":{
      
      // background:'red',
      // border:'2px solid #B158E7',
      border: (props) => {
        switch (props.userType) {
          case "GOLD":
            return "3px solid #FF961C";
          case "SILVER":
            return "3px solid #9F9F9F";
          case "DIAMOND":
            return "3px solid #B158E7";
          default:
            return "3px solid #2F82FF";
        }
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: "-8px",
        right: "-6px",
        width: "20px",
        height: "20px",
        borderRadius: '50%',
        // backgroundColor: props => props.badgeColor || 'silver', // Default to silver
        // border: '2px solid white',
        background: (props) => {
          switch (props.userType) {
            case "GOLD":
              return "url(/images/Profile/Gold.svg)";
            case "SILVER":
              return "url(/images/Profile/Silver.svg)";
            case "DIAMOND":
              return "url(/images/Profile/Diamond.svg)";
            default:
              return "url(/images/Profile/Base.svg)";
          }
        },
      },
    },


    "& .MuiOutlinedInput-root ": {
      height: "44px",
      borderRadius: "10px",
    },
    "& .MuiSelect-icon": {
      // top: "4px",
      fontSize: "x-large",
    },
    "& .MuiList-root": {
      paddingRight: "40px",
    },

    "& .MuiSelect-select ": {
      border: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  },

  ecoButton: {
    borderRadius: "10px",
    width: "100%",
    minWidth: "225px",
  },

  signUpBtn: {
    background: "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
  },

  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "140px",
  },
  drawerContainer: {
    boxSizing: "border-box",
    padding: "20px",
    height: "100%",
    background: "#ffffff",
    color: "#262626",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& .MuiSelect-select ": {
      border: "none",
    },
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
      width: "75px",
    },
  },
  menuMobile: {
    fontSize: "14px",
    fontWeight: "500",
    paddingLeft: "10px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    "& .MuiAvatar-root":{
      // background:'green',
      // border:'2px solid #B158E7',
      overflow:"visible",
      "& img":{
        overflow:'hidden',
        borderRadius:'50%'
      },
    },

    "& .userProfileTypeIcon":{
      
      // background:'red',
      // border:'2px solid #B158E7',
      border: (props) => {
        switch (props.userType) {
          case "GOLD":
            return "3px solid #FF961C";
          case "SILVER":
            return "3px solid #9F9F9F";
          case "DIAMOND":
            return "3px solid #B158E7";
          default:
            return "3px solid #2F82FF";
        }
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: "-8px",
        right: "-6px",
        width: "20px",
        height: "20px",
        borderRadius: '50%',
        // backgroundColor: props => props.badgeColor || 'silver', // Default to silver
        // border: '2px solid white',
        background: (props) => {
          switch (props.userType) {
            case "GOLD":
              return "url(/images/Profile/Gold.svg)";
            case "SILVER":
              return "url(/images/Profile/Silver.svg)";
            case "DIAMOND":
              return "url(/images/Profile/Diamond.svg)";
            default:
              return "url(/images/Profile/Base.svg)";
          }
        },
      },
    },
  },
  search: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#DAF4FF",
    border: "1px solid #fff",
    marginLeft: 20,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: "0px 9px",
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: "8px 6px 8px 0px",
    fontSize: "12px",
    marginTop: "-2px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
  },
  searcBox: {
    backgroundColor: "#DAF4FF",
    borderRadius: " 50px",
  },

  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 100px)",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      overflowY: "auto",
    },
  },
  selectHeader: {
    "& .MuiInputBase-input": {
      color: "#636880",
      fontSize: "16px",
      paddingTop: "10px",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
  },

  mobileDraweSelect: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
    marginTop: "20px",
  },

  mobileMenuItem: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    fontSize: "16px",
    color: "#262626",
    width:'100%'
  },

  transparentTextfield: {
    "& .MuiOutlinedInput-root": {
      background: "rgba(255,255,255,0.2)",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiOutlinedInput-input": {
      "&::placeholder": {
        color: "rgba(177, 177, 177, 1)",
      },
    },

    "& .MuiInputBase-input": {
      color: "rgba(255,255,255,0.8)",
    },
  },
  PopOverClass: {
    "& .MuiPopover-paper": {
      padding: "20px 0 18px 0 ",
      width: "100%",
      maxWidth: "220px",
      position: "absolute",
    },
  },
  profileMenuItem: {
    "& .MuiAvatar-root":{
      // background:'green',
      // border:'2px solid #B158E7',
      overflow:"visible",
      "& img":{
        overflow:'hidden',
        borderRadius:'50%'
      },
    },
"& .userProfileTypeIcon":{
      
      // background:'red',
      // border:'2px solid #B158E7',
      border: (props) => {
        switch (props.userType) {
          case "GOLD":
            return "3px solid #FF961C";
          case "SILVER":
            return "3px solid #9F9F9F";
          case "DIAMOND":
            return "3px solid #B158E7";
          default:
            return "3px solid #2F82FF";
        }
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: "-8px",
        right: "-6px",
        width: "20px",
        height: "20px",
        borderRadius: '50%',
        // backgroundColor: props => props.badgeColor || 'silver', // Default to silver
        // border: '2px solid white',
        background: (props) => {
          switch (props.userType) {
            case "GOLD":
              return "url(/images/Profile/Gold.svg)";
            case "SILVER":
              return "url(/images/Profile/Silver.svg)";
            case "DIAMOND":
              return "url(/images/Profile/Diamond.svg)";
            default:
              return "url(/images/Profile/Base.svg)";
          }
        },
      },
    },
    backgroundColor: "white",
    gap: "10px",
    alignItems: "center",
    padding: "0",
    "& .btnProfile": {
      whiteSpace: "pre",
      border: "none",
    },
    "& .btnProfile": {
      whiteSpace: "pre",
      border: "none",
      "& h5": {
        fontSize: "16px",
        color: "#262626",
        display: "flex",
        alignItems: "start",
      },
    },
  },
  profileAccount: {
    backgroundColor: "white",
    gap: "10px",
    alignItems: "center",
    marginTop: "10px",
    "& .myAccTypo": {
      fontSize: "16px",
      color: "#262626",
      width:"100%"
    },
  },
  suggestions: {
    color: "#262626",
    position: "absolute",
    backgroundColor: "#fff",
    top: "44px",
    borderRadius: "10px",
    background: "#fff",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    width: "100%",
    maxWidth: "400px",
    "& .suggestionslist": {
      cursor: "pointer",
      paddingLeft: "20px",
      "& li": {
        marginBottom: "6px",
      },
    },
  },
}));

export default function Header() {
  const auth = useContext(AuthContext);
  const userType = auth?.profileData?.userType ;
  console.log(userType,"userTypeHome");
  const classes = useStyles({userType});
  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    mainHeader,
    mobileDraweSelect,
    mobileMenuItem,
    transparentTextfield,
  } = useStyles({userType});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const history = useHistory();
  const globalSearch = auth?.globalSearch;
  const setGlobalSearch = auth?.setGlobalSearch;
  const showEndAdornment = globalSearch.length > 0;

  const [userLoggedIn, setUserLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    setUserLoggedIn(token !== null && token.trim() !== "");
  }, []);

  const profileData = auth?.profileData;



  const [coeSelect, setCoeSelect] = useState("");
  const [marketplaceSelect, setMarketplaceSelect] = useState("");

  const [mobileExplore, setMobileExplore] = useState("");
  const [defaultSearch, setDefaultSearch] = useState("");
  const [open, setOpen] = useState(false);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLogout, setIsLogout] = useState(false);
  const [selectedPage, setSelectedPage] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleLogoutClose = () => {
    setIsLogout(false);
  };

  const handleClose = () => {
    setAnchorEl2(null);
  };


  const skillMarketPlaceRoute =
    history.location.pathname === "/skills-marketplace" ||
    history.location.pathname === "/skill-bridge" ||
    history.location.pathname === "/skill-drops";

  let token = localStorage.getItem("token");

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const handleCartButtonClick = () => {
    if (userLoggedIn) {
      // Navigate only if user is logged in
      history.push("/cart");
    }
  };
  useEffect(() => {
    const unlisten = history.listen(() => {
      myFunction();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const myFunction = () => {
    if (localStorage.getItem("selectedTab")) {
      localStorage.removeItem("selectedTab");
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    fetchSuggestions(value);
  };

  const handleSelectSuggestion = (suggestion, index) => {
    history.push({
      pathname: "/skills-marketplace",
      state: {
        categoryId: suggestion?._id,
        categoryName: suggestion?.categoryName,
        description: suggestion?.description,
        bannerImage: suggestion?.bannerImage,
        categoryImage: suggestion?.categoryImage,
        userId: index + 1,
      },
    });
    setQuery(suggestion);
    setSuggestions([]);
  };

  const handlePush = () => {
    history.push("/skills-marketplace");
  };
  const chatBothandle = (event) => {
    // const token = localStorage.getItem('token');
    // event.preventDefault();
    // if (!token) {
    //   history.push({
    //     pathname: "/login",
    //     state: { type: "WOL" },
    //   });
    // } else {
    //   history.push("/trendskills-ai");
    // }
    history.push("/trendskills-ai");
  };

  const handlePushe = () => {
    if (userLoggedIn) {
      // Navigate only if user is logged in
      history.push("/my-resume");
    } else {
      history.push("/login");
    }
  };
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const fetchSuggestions = async () => {
    try {
      const params = {};
      if (searchQuery) {
        params.name = searchQuery;
      }
      const res = await axios({
        method: "GET",
        url: apiConfig.getAllCategory,
        params: params,
      });
      if (res?.data?.responseCode === 200) {
        const filteredSuggestions = res?.data?.result?.docs.filter(
          (suggestion) =>
            suggestion.categoryName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      }
    } catch (error) {
      if (error?.response) {
        // toast.error(error?.response?.data?.responseMessage);
      }
    }
  };


  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            gap: "30px",
            marginRight: "10px",
          }}
        >
          {femmecubatorLogo}

          {/* <HeaderSearch /> */}
        </Box>

        <div
          className={
            skillMarketPlaceRoute ? "optionWrapperTransParent" : "optionWrapper"
          }
        >
          {/* <Button
            variant="body2"
            style={{
              fontWeight: 400,
              fontSize: "16px",
              border: "none",
              padding: " 2px 4px",
              width: "124px",
              color: skillMarketPlaceRoute ? "rgba(255,255,255,0.8)" : "",
            }}
            onClick={handlePushe}
          >
            Create AI Resume
          </Button> */}

          {/* <Button
          component="a"
          href="/trendskills-ai"
            variant="body2"
            style={{
              fontWeight: 400,
              fontSize: "16px",
              border: "none",
              padding: " 2px 4px",
              color: skillMarketPlaceRoute ? "rgba(255,255,255,0.8)" : "",
              width: "115px"

            }}
            onClick={chatBothandle}
          >
            TrendSkills AI
            </Button> */}

          <PopupState variant="popover" popupId="popup-popover1">
            {(popupState) => (
              <div>
                <div
                  className={
                    skillMarketPlaceRoute ? "marketPlaceOption" : "option"
                  }
                  {...bindTrigger(popupState)}
                >
                  <span>
                    About Us
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div
                      style={{
                        // borderRight: "1px solid #dcdcdc",
                        cursor: "pointer",
                      }}
                    >
                      {/* <p onClick={() => history.push("/about-us")}>
                        <a href="/about-us">
                          <img src="/images/Aicoe2.png"  alt="" /> About Us
                        </a>
                      </p> */}
                      <p >
                        <a href="/about-us">
                          <img src="/images/Aicoe2.png"  alt="" />About Us
                        </a>
                      </p>
                      <p >
                        <a href="/blog">
                          <img src="/images/Aicoe2.png"  alt="" />Blog
                        </a>
                      </p>
                      <p >
                        <a href="/plans">
                          <img src="/images/Aicoe2.png"  alt="" />Plans
                        </a>
                      </p>
                      <p>
                        <a href="/educators">
                          <img src="/images/Aicoe2.png"  alt="" />Educators
                        </a>
                      </p>
                      <p>
                        <a href="/employers">
                          <img src="/images/Aicoe2.png"  alt="" />Employers
                        </a>
                      </p>
                      <p 
                      // onClick={() => history.push("/trend-skills-ai")}
                      >
                        <a href="/trend-skills-ai">
                          <img src="/images/Aicoe2.png"  alt="" />TrendSkillsAI
                        </a>
                      </p>
                      
                      <p >
                        <a href="/digital-navigator-ai">
                          <img src="/images/Aicoe2.png"  alt="" />DigitalNavigatorAI
                        </a>
                      </p>

                      
                      {/* <p
                        onClick={() =>
                          window.open(process.env.REACT_APP_GYAN_CHAIN, "_blank")
                        }
                      >
                        <a href={process.env.REACT_APP_GYAN_CHAIN} target="_blank" >
                          <img src="/images/blockchain_img.svg" alt="" /> Gyan chain
                        </a>
                      </p>

                      <p
                        onClick={() =>
                          window.open(process.env.REACT_APP_GYAN_KYC, "_blank")
                        }
                      >
                        <a href={process.env.REACT_APP_GYAN_KYC} target="_blank" >
                        <img src="/images/catalouge_img.svg" alt="" /> Gyan KYC
                        </a>
                      </p>

                      

                      <p onClick={() => history.push("/faq")}>
                        <a  href="/faq"><img src="/images/faq_img.svg" alt="" /> FAQs</a>
                      </p> */}
                    </div>
                    {/* <div
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <p onClick={() => history.push("/faq")}>
                        <a  href="/faq"><img src="/images/faq_img.svg" alt="" /> FAQs</a>
                      </p>
                      <p onClick={() => history.push("/media")}>
                        <a href="/media">                          
                        <img src="/images/media_img.svg" alt="" /> Media
                        </a>
                      </p>
                      <p onClick={() => history.push("/contact-us")}>
                        <a href="/contact-us">
                           <img src="/images/iconcontact-us.png" alt="" style={{ height: '15px', width: '15px' }} /> Contact Us
                       </a>
                      </p>

                    </div> */}
                  </div>
                </Popover>
              </div>
            )}
          </PopupState>
          
          <a href="/skill-drops">
            <Button
              variant="body2"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                border: "none",
                padding: " 2px 4px", width: "80px",
                color: skillMarketPlaceRoute ? "rgba(255,255,255,0.8)" : "",
              }}
            >
              Skill Drops
            </Button>
          </a>

          {/* <a href="/skill-bridge">
            <Button
              variant="body2"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                border: "none",
                padding: " 2px 4px",
                width: "100px",
                color: skillMarketPlaceRoute ? "rgba(255,255,255,0.8)" : "",
              }}
              onClick={()=>{
                          history.push("/skill-bridge")
                        }}
            >
              Skills Bridge
            </Button>
          </a> */}

          {/* <Button
            variant="body2"
            style={{
              fontWeight: 400,
              fontSize: "16px",
              border: "none",
              padding: " 2px 4px",
              color: skillMarketPlaceRoute ? "rgba(255,255,255,0.8)" : "",
            }}
            onClick={handlePush}
          >
            Marketplace
          </Button> */}
          {/* <PopupState variant="popover" popupId="popup-popover2">
            {(popupState) => (
              <div>
                <div
                  className={
                    skillMarketPlaceRoute ? "marketPlaceOption" : "option"
                  }
                  {...bindTrigger(popupState)}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                  Marketplace
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div>

                    <p
                        onClick={handlePush}
                        style={{ cursor: "pointer" }}
                      > <a href="/skills-marketplace">
                         <img src="/images/grid-01.svg" alt="" /> Skills Marketplace
                        </a>
                      </p>
                      
                   

                      <p
                        style={{ cursor: "pointer" }}
                        onClick={()=>{
                          history.push("/skill-bridge")
                        }}
                      >
                        <a href="/skill-bridge">
                       <img src="/images/Skills Bridge.svg" alt="" /> Skills Bridge5
                        </a>
                      </p>

                      <p
                        onClick={() => history.push("/all-categories")}
                        style={{ cursor: "pointer" }}
                      >  <a href="/all-categories">
                          <img src="/images/dotpoints-02.svg" alt="" /> All Categories
                         </a>
                      </p>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </PopupState> */}

          {/* <a href="/skills-marketplace">
            <Button
              variant="body2"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                border: "none",
                padding: " 2px 4px", width: "130px",
                color: skillMarketPlaceRoute ? "rgba(255,255,255,0.8)" : "",
              }}
              onClick={() => history.push("/skills-marketplace")}
            >
              Skills Marketplace
            </Button>
          </a> */}

          <a href="/subscriptions">
            <Button
              variant="body2"
              style={{
                fontWeight: 400,
                fontSize: "16px",
                border: "none",
                padding: " 2px 4px", width: "96px",
                color: skillMarketPlaceRoute ? "rgba(255,255,255,0.8)" : "",
              }}
            >
              Subscriptions
            </Button>
          </a>

          <PopupState variant="popover" popupId="popup-popover1">
            {(popupState) => (
              <div>
                <div
                  className={
                    skillMarketPlaceRoute ? "marketPlaceOption" : "option"
                  }
                  {...bindTrigger(popupState)}
                >
                  <span>
                    Explore
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div
                      style={{
                        // borderRight: "1px solid #dcdcdc",
                        cursor: "pointer",
                      }}
                    >
                                            
                      <p
                        onClick={() =>
                          window.open(process.env.REACT_APP_GYAN_CHAIN, "_blank")
                        }
                      >
                        <a href={process.env.REACT_APP_GYAN_CHAIN} target="_blank" >
                          <img src="/images/blockchain_img.svg" alt="" /> Gyan chain
                        </a>
                      </p>

                      <p
                        onClick={() =>
                          window.open(process.env.REACT_APP_GYAN_KYC, "_blank")
                        }
                      >
                        <a href={process.env.REACT_APP_GYAN_KYC} target="_blank" >
                        <img src="/images/catalouge_img.svg" alt="" /> Gyan KYC
                        </a>
                      </p>
                    </div>
                   
                  </div>
                </Popover>
              </div>
            )}
          </PopupState>
          

          <PopupState variant="popover" popupId="popup-popover0">
            {(popupState) => (
              <div>
                <div
                  className={
                    skillMarketPlaceRoute ? "marketPlaceOption" : "option"
                  }
                  {...bindTrigger(popupState)}
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    I & AI CoEs
                    <KeyboardArrowDown style={{ fontSize: "13px" }} />
                  </span>
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div
                    className="dropContainer"
                    style={{ display: "flex", alignitems: "center" }}
                  >
                    <div>
                      
                      <p
                        // onClick={() => history.push("/about-coe-program")}
                        style={{ cursor: "pointer" }}
                      >
                        <a href="/about-coe-program">
                          <img src="/images/Aicoe2.png" alt="" /> About I & AI CoEs
                        </a>
                      </p>
                      <p
                        // onClick={() => history.push("/coe-partners")}
                        style={{ cursor: "pointer" }}
                      >
                        <a href="/coe-partners">
                          <img src="/images/Aicoe2.png" alt="" /> COE partners
                        </a>
                      </p>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </PopupState>



          {token ? (
            // <Button
            //   variant="containedPrimary"
            //   style={{
            //     fontSize: "14px",

            //     whiteSpace: "pre",
            //     cursor: "pointer",
            //     height: "40px",
            //     padding: "9px 15px",
            //     border: "1px solid transparent",
            //   }}
            //   onClick={() => history.push("/profile/my-wallet")}
            // >
            //   Dashboard
            // </Button>
            <></>
          ) : (
            <Button
              variant="containedPrimary"
              style={{
                fontSize: "14px",

                whiteSpace: "pre",
                cursor: "pointer",
                height: "40px",
                padding: "9px 15px",
                border: "1px solid transparent",
              }}
              onClick={() => history.push("/login")}
            >
              Get Started
            </Button>
          )}

          {/* {token && (
            <Box style={{ whiteSpace: "pre", height: "40px" }}>
              <Button
                variant="outlined"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                }}
                onClick={handleCartButtonClick}
              >
                <GrCart style={{ color: "grey" }} />
              </Button>
            </Box>
          )} */}

          {token && (
            <Box>
              <Button
                variant="outlined"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                }}
                onClick={handleClick}
              >
                {/* {" "}
                {profileData?.firstName && (
                  <>
                    <Typography style={{ color: "rgba(38, 38, 38, 1)" }}>
                      {profileData
                        ? profileData.firstName &&
                          profileData.firstName.length > 8
                          ? profileData.firstName.slice(0, 8) + "..."
                          : profileData.firstName || ""
                        : ""}
                    </Typography>
                    <ExpandMoreIcon />
                  </>
                )} */}
                <Avatar
                  src={profileData ? profileData?.profileImage : ""}
                  style={{ color: "grey" }}
                  className="userProfileTypeIcon"
                />
              </Button>
            </Box>
          )}
        </div>
        <Popover
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={handleClose}
          className={classes.PopOverClass}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem className={classes.profileMenuItem}>
            <Button variant="outlined" className="btnProfile"
              onClick={() => {
              setOpen(true);
              handleClose();
              history.push("/profile/my-wallet");
            }}>
              <Avatar
                src={profileData ? profileData?.profileImage : ""}
                style={{ color: "grey" }}
                className="userProfileTypeIcon"
              />
              <Box style={{ paddingLeft: "20px" }}>
                <Typography variant="h5">
                  {" "}
                  {profileData
                    ? `${profileData.firstName &&
                      profileData.firstName.length > 5
                      ? profileData.firstName.slice(0, 5) + "..."
                      : profileData.firstName || ""
                    } ${profileData.lastName && profileData.lastName.length > 5
                      ? profileData.lastName.slice(0, 5) + "..."
                      : profileData.lastName || ""
                    }`
                    : ""}
                </Typography>
                <Typography variant="body2">
                  {profileData && profileData.hashCode
                    ? `${profileData.hashCode.slice(
                      0,
                      7
                    )}..${profileData.hashCode.slice(-7)}`
                    : ""}
                </Typography>
              </Box>
            </Button>
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.profileAccount}
            onClick={() => {
              setOpen(true);
              handleClose();
              history.push("/profile/my-wallet");
            }}
          >
            <PersonOutlineIcon style={{ color: "#000000" }} />
            <Typography className="myAccTypo">My Account</Typography>
          </MenuItem>
          <MenuItem className={classes.profileAccount}>
            <PowerSettingsNewIcon style={{ color: "#000000" }} />
            <Typography
              onClick={() => {
                setIsLogout(true);
                // history.push("/");
              }}
              className="myAccTypo"
            >
              Logout
            </Typography>
            {isLogout && (
              <CommonPopup
                type="logout1"
                open={isLogout}
                onClose={handleLogoutClose}
                title="Logout"
                text="Are you sure you want to log out?"
              />
            )}
          </MenuItem>
        </Popover>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));

    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Box mb={1}>
              <Link to="/">
                <img className={logoDrawer} src="/images/logo.svg" alt="" width="100%" />
              </Link>
            </Box>
            <div className={mobileDraweSelect}>
              {/* <Button
                variant="body2"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                }}
                onClick={handlePushe}
              >
                Create AI Resume
              </Button> */}
              {/* <a href="/trendskills-ai"> */}
              {/* <Button
                component="a"
                href="/trendskills-ai"
                variant="body2"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                  width:'100%'
                }}
                onClick={chatBothandle}
              >
                TrendSkills AI
                </Button> */}
                {/* </a> */}

                <Select
                variant="outlined"
                displayEmpty
                value={mobileExplore}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  About Us
                </MenuItem>
                <a href="/about-us" className="mainHeading">
                <MenuItem
                  value="aboutUs"
                  // onClick={() => history.push("/about-us")}
                  className={mobileMenuItem}
                >
                    <img src="/images/Aicoe2.png"  />
                  About Us
                </MenuItem>
                  </a>

                <a href="/blog" className="mainHeading">
                  <MenuItem
                    value="blog"
                    // onClick={() => history.push("/blog")}
                    className={mobileMenuItem}
                  >
                      <img src="/images/Aicoe2.png"  />
                      Blog
                  </MenuItem>
                </a>

                  <a href="/plans" className="mainHeading">
                <MenuItem
                  value="Plans"
                  // onClick={() => history.push("/plans")}
                  className={mobileMenuItem}
                >
                    <img src="/images/Aicoe2.png"  />
                    Plans
                </MenuItem>
                  </a>
                  <a href="/educators" className="mainHeading">
                <MenuItem
                  value="Educators"
                  // onClick={() => history.push("/educators")}
                  className={mobileMenuItem}
                >
                    <img src="/images/Aicoe2.png"  />
                    Educators
                </MenuItem>
                  </a>
                  <a href="/employers" className="mainHeading">
                <MenuItem
                  value="Employers"
                  // onClick={() => history.push("/employers")}
                  className={mobileMenuItem}
                >
                    <img src="/images/Aicoe2.png"  />
                    Employers
                </MenuItem>
                  </a>
                  <a href="/trend-skills-ai" className="mainHeading">
                <MenuItem
                  value="TrendSkillsAi"
                  // onClick={() => history.push("/trend-skills-ai")}
                  className={mobileMenuItem}
                >
                    <img src="/images/Aicoe2.png"  />
                    TrendSkillsAI
                </MenuItem>
                  </a>
                  
                  <a href="/digital-navigator-ai" className="mainHeading">
                <MenuItem
                  value="DigitalNavigatorAI"
                  // onClick={() => history.push("/digital-navigator-ai")}
                  className={mobileMenuItem}
                >
                    <img src="/images/Aicoe2.png"  />
                    DigitalNavigatorAI
                </MenuItem>
                  </a>

                  {/* <a  href={process.env.REACT_APP_GYAN_CHAIN} target="_blank" className="mainHeading">
                <MenuItem
                  value="aboutUs"
                  onClick={() => window.open(process.env.REACT_APP_GYAN_CHAIN, "_blank")}
                  className={mobileMenuItem}
                >
                    <img src="/images/blockchain_img.svg" />
                    Gyan Chain
                </MenuItem>
                  </a>
                  <a href={process.env.REACT_APP_GYAN_KYC} target="_blank" className="mainHeading">
                  <MenuItem
                    value="aboutUs"
                    onClick={() =>
                      window.open(process.env.REACT_APP_GYAN_KYC, "_blank")
                    }
                    className={mobileMenuItem}
                  >
                      <img src="/images/catalouge_img.svg" alt="" />
                    Gyan KYC
                  </MenuItem>
                  </a>
                  <a href="/faq" className="mainHeading">
                <MenuItem
                  value="media"
                  onClick={() => history.push("/faq")}
                  className={mobileMenuItem}
                >
                    <img src="/images/faq_img.svg" alt="" />
                  FAQs
                </MenuItem>
                  </a> */}
                  {/* <a  href="/media" className="mainHeading">
                <MenuItem
                  value="media"
                  onClick={() => history.push("/media")}
                  className={mobileMenuItem}
                >
                   <img src="/images/media_img.svg" />
                  Media
                </MenuItem>
                 </a>
                 <a href="/contact-us" className="mainHeading">
                <MenuItem
                  value="media"
                  onClick={() => history.push("/contact-us")}
                  className={mobileMenuItem}
                >
                    <img src="/images/iconcontact-us.png" style={{ height: '15px', width: '15px' }} />
                    Contact Us
                </MenuItem>
                  </a> */}
              </Select>

              <a href="/skill-drops">
                <Button
                  variant="body2"
                  style={{
                    whiteSpace: "pre",
                    border: "none",
                    background: "#C6C6C62B",
                    padding: "8px 8px",
                    height: "100%",
                    width:'100%'
                  }}
                >
                  Skill Drops
                </Button>
              </a>
              {/* <a href="/skill-bridge">
                <Button
                  variant="body2"
                  style={{
                    whiteSpace: "pre",
                    border: "none",
                    background: "#C6C6C62B",
                    padding: "8px 8px",
                    height: "100%",
                    width:'100%'
                  }}
                  onClick={() => history.push("/skill-bridge")}
                >
                  Skills Bridge
                </Button>
              </a> */}

              {/* <Button
                variant="body2"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                }}
                onClick={handlePush}
              >
                Marketplace
              </Button> */}
              {/* <Select
                variant="outlined"
                displayEmpty
                value={marketplaceSelect}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                Marketplace 
                </MenuItem>
                <a href="/skills-marketplace" className="mainHeading" >
                  <MenuItem
                    value="existingPartner"
                    className={mobileMenuItem}
                    onClick={() => history.push("/skills-marketplace")}
                  >
                    <img src="/images/grid-01.svg" alt="" /> Skills Marketplace
                  </MenuItem>
                </a> 
                <a href="/skill-bridge" className="mainHeading">
                <MenuItem
                  value="existingPartner"
                  className={mobileMenuItem}
                  onClick={() => history.push("/skill-bridge")}
                >
                  <img src="/images/Skills Bridge.svg" alt="" /> Skills Bridge6
                </MenuItem>
                </a>
                <a href="/all-categories" className="mainHeading">
                <MenuItem
                  value="about"
                  className={mobileMenuItem}
                  onClick={() => history.push("/all-categories")}
                >
                    <img src="/images/dotpoints-02.svg" alt="" /> All Categories
                </MenuItem>
                </a>
              </Select> */}

              {/* <a href="/skills-marketplace">
                <Button
                  variant="body2"
                  style={{
                    whiteSpace: "pre",
                    border: "none",
                    background: "#C6C6C62B",
                    padding: "8px 8px",
                    height: "100%",
                    width:'100%'
                  }}
                  onClick={() => history.push("/skills-marketplace")}
                >
                  Skills Marketplace
                </Button>
              </a> */}

              <a href="/subscriptions">
                <Button
                  variant="body2"
                  style={{
                    whiteSpace: "pre",
                    border: "none",
                    background: "#C6C6C62B",
                    padding: "8px 8px",
                    height: "100%",
                    width:'100%'
                  }}
                 
                >
                  Subscriptions
                </Button>
              </a>

              <Select
                variant="outlined"
                displayEmpty
                value={mobileExplore}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  Explore
                </MenuItem>
                  <a  href={process.env.REACT_APP_GYAN_CHAIN} target="_blank" className="mainHeading">
                <MenuItem
                  value="GyanChain"
                  onClick={() => window.open(process.env.REACT_APP_GYAN_CHAIN, "_blank")}
                  className={mobileMenuItem}
                >
                    <img src="/images/blockchain_img.svg" />
                    Gyan Chain
                </MenuItem>
                  </a>
                  <a href={process.env.REACT_APP_GYAN_KYC} target="_blank" className="mainHeading">
                  <MenuItem
                    value="GyanKYC"
                    onClick={() =>
                      window.open(process.env.REACT_APP_GYAN_KYC, "_blank")
                    }
                    className={mobileMenuItem}
                  >
                      <img src="/images/catalouge_img.svg" alt="" />
                    Gyan KYC
                  </MenuItem>
                  </a>
                  
              </Select>

              <Select
                variant="outlined"
                displayEmpty
                value={coeSelect}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  I & AI CoEs
                </MenuItem>
                
                {/* <MenuItem
                  value="coes"
                  className={mobileMenuItem}
                  onClick={() => history.push("/coes")}
                >
                  <img src="/images/Aicoe2.png" alt="" /> I & AI CoEs
                </MenuItem> */}
                  <a href="/about-coe-program" className="mainHeading" >
                <MenuItem
                  value="about"
                  className={mobileMenuItem}
                  onClick={() => history.push("/about-coe-program")}
                >
                  <img src="/images/Aicoe2.png" alt="" /> About I & AI CoEs
                </MenuItem>
                  </a>

                  <a href="/coe-partners" className="mainHeading">
                <MenuItem
                  value="existingPartner"
                  className={mobileMenuItem}
                  // onClick={() => history.push("/coe-partners")}
                >
                     <img src="/images/Aicoe2.png" alt="" /> COE Partners
                </MenuItem>
                 </a>
              </Select>

              {/* <Select
                variant="outlined"
                displayEmpty
                value={coeSelect}
                IconComponent={KeyboardArrowDown}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  Stats
                </MenuItem>

                <a href="/ranking" className="mainHeading" >
                <MenuItem
                  value="partnerWithUs"
                  onClick={() => history.push("/ranking")}
                  className={mobileMenuItem}
                >
                    <img src="/images/stats1.png" alt="" />
                     Ranking
                </MenuItem>
                  </a>

                  <a href="/activity" className="mainHeading">
                    <MenuItem
                      value="existingPartner"
                      onClick={() => history.push("/activity")}
                      className={mobileMenuItem}
                    >
                        <img src="/images/stats1.png" alt="" /> Activity
                    </MenuItem>
                  </a>
              </Select> */}

              {token ? (
                <Button
                  variant="containedPrimary"
                  style={{
                    fontSize: "14px",

                    whiteSpace: "pre",
                    cursor: "pointer",
                    height: "40px",
                    padding: "9px 15px",
                    border: "1px solid transparent",
                  }}
                  onClick={() => history.push("/profile/my-wallet")}
                >
                  Dashboard
                </Button>
              ) : (
                <Button
                  variant="containedPrimary"
                  style={{
                    fontSize: "14px",

                    whiteSpace: "pre",
                    cursor: "pointer",
                    height: "40px",
                    padding: "9px 15px",
                    border: "1px solid transparent",
                  }}
                  onClick={() => history.push("/login")}
                >
                  Get Started
                </Button>
              )}

              {/* <a  href="/cart">
                <Button
                variant="outlined"
                style={{
                  whiteSpace: "pre",
                  border: "none",
                  background: "#C6C6C62B",
                  padding: "8px 8px",
                  height: "100%",
                  width:'100%'
                }}
                onClick={() => {
                  history.push("/cart");
                }}
              >
                <GrCart style={{ color: "grey" }} />
              </Button>
              </a> */}
            </div>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "blue", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo
          skillMarketPlaceRoute={skillMarketPlaceRoute}
          className="logoImg"
        />
      </Link>
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        className={classes.appBar}
        style={{
          backgroundColor: skillMarketPlaceRoute
            ? "rgba(255,255,255,0.03)"
            : "#FFF",
          border: "none",
        }}
      >
        <Container style={{ maxWidth: "100%" }} >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      {dialogOpen && (
        <Paper>
          <Dialog
            fullWidth
            maxWidth="lg"
            className={classes.searchdiaogBox}
            style={{
              position: "absolute",
              top: "10%",
            }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <IconButton
              className="closeButton"
              onClick={() => setDialogOpen(false)}
            >
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </IconButton>

            <Box className="dialogBoxHeight">
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </Box>
          </Dialog>
        </Paper>
      )}
    </>
  );
}
