import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { apiConfig } from "src/apiconfig/ApiConfig";
import { CiSearch } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    flex: "1 0 150px",
    position: "relative",
    maxWidth: "400px",
  },
  popupBox: {
    width: "400px",
    maxHeight: "266px",
    position: "absolute",
    backgroundColor: "white",
    zIndex: 1,
    overflowY: "auto",
    borderRadius: "8px",
    marginTop: "8px",
    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
  },
  section: {
    padding: "10px 15px",
    borderBottom: "1px solid #e0e0e0",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: "8px",
    color: "#182230",
  },
  itemBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    cursor: "pointer",
  },
  imageBox: {
    minWidth: "40px",
    width: "40px",
    height: "40px",
    marginRight: "10px",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "7px",
      objectFit: "cover",
    },
  },
  dataName: {
    fontFamily: "'Inter', sans-serif ",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#182230",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  noResults: {
    padding: "20px",
    textAlign: "center",
    color: "#888",
  },
}));

const HeaderSearch = () => {
  const classes = useStyles();
  const history = useHistory();
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [courses, setCourses] = useState([]);

  const fetchSearchResults = async () => {
    if (searchValue.trim() === "") {
      clearSearch();
      return;
    }

    try {
      setLoading(true);
      const params = {
        search: searchValue,
      };

      const res = await axios.get(apiConfig.globalSearchApi, { params });

      if (res?.data?.responseCode === 200) {
        const data = res?.data?.result;
        setCategories(data?.categories || []);
        setSubcategories(data?.subcategories || []);
        setCourses(data?.courses || []);
      } else {
        setCategories([]);
        setSubcategories([]);
        setCourses([]);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setCategories([]);
      setSubcategories([]);
      setCourses([]);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isPopupOpen && searchValue.trim() !== "") {
  //     fetchSearchResults();
  //   }
  // }, [searchValue, isPopupOpen]);

  const handleClick = () => {
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;

    const sanitizedValue = value
      .replace(/<html.*?>.*?<\/html>/gi, "")
      .replace(/<script.*?>.*?<\/script>/gi, "")
      .replace(/<.*?on\w+=".*?"/gi, "")
      .replace(/javascript:/gi, "")
      .replace(/SELECT.*FROM|UNION.*SELECT|--/gi, "")
      .replace(/ {2,}/g, " ")
      .trimStart();

    setSearchValue(sanitizedValue);
  };

  const clearSearch = () => {
    setSearchValue("");
    setIsPopupOpen(false);
    setCategories([]);
    setSubcategories([]);
    setCourses([]);
  };
  const formatStringForURL = (str) => {
    if (str) {
      return str
        .toLowerCase()
        .replace(/[^a-z0-9\s]+/g, "-") // Replace non-alphanumeric characters with hyphens
        .trim() // Remove any leading or trailing spaces
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen
        .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
    } else {
      return;
    }
  };

  const navigateTo = (item, type) => {
    if (type === "category") {
      // history.push({
      //   pathname: `/category/${item.categoryName
      //     .replace(/-/g, "_")
      //     .replace(/\s+/g, "-")
      //     .toLowerCase()}`,
      //   state: {
      //     categoryId: item._id,
      //     categoryName: item.categoryName,
      //     description: item.description,
      //     bannerImage: item.bannerImage,
      //     categoryImage: item.categoryImage,
      //   },
      // });
      return;
    } else if (type === "subcategory") {
      // history.push({
      //   pathname: `/courses/${item.subCategoryName
      //     .replace(/-/g, "_")
      //     .replace(/\s+/g, "-")
      //     .toLowerCase()}`,
      //   state: {
      //     id: item._id,
      //     bannerImage: item.bannerImage,
      //     Description: item.description,
      //     short: item.short_description,
      //     name: item.subCategoryName,
      //     image: item.subCategoryImage,
      //   },
      // });
      return;
    } else if (type === "course") {
      if (item?.is_payable === true) {
        // history.push({
        //   pathname: `/skills-marketplace/${formatStringForURL(item?.categoryId?.categoryName)}/${formatStringForURL(item?.subCategory_id?.subCategoryName)}/${item?.seoUrl}`,
        //   state: {
        //     id: item._id,
        //   },
        // });
        return;
      } else {
        history.push({
          pathname: `/skill-drops/${formatStringForURL(
            item?.categoryId?.categoryName
          )}/${formatStringForURL(item?.subCategory_id?.subCategoryName)}/${
            item?.seoUrl
          }`,
          state: {
            id: item._id,
          },
        });
      }
    }
    window.scrollTo(0, 0);
    handleClose();
  };

  const renderSection = (title, items, type) => {
    if (items.length === 0) return null;

    return (
      <Box className={classes.section}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          {title}
        </Typography>
        {items.length > 0 &&
          items
            .filter((item) => item?.is_payable === false)
            .map((item) => (
              <Box
                key={item._id || item.otherCourseName}
                className={classes.itemBox}
                onClick={() => navigateTo(item, type)}
              >
                <Box className={classes.imageBox}>
                  <img
                    src={
                      type === "category"
                        ? item.categoryImage || "/images/searchReactangle.svg"
                        : type === "subcategory"
                        ? item.subCategoryImage ||
                          "/images/searchReactangle.svg"
                        : item.thumbnail || "/images/searchReactangle.svg"
                    }
                    alt="img"
                  />
                </Box>
                <Typography variant="body1" className={classes.dataName}>
                  {type === "category"
                    ? item.categoryName
                    : type === "subcategory"
                    ? item.subCategoryName
                    : item.otherCourseName}
                </Typography>
              </Box>
            ))}
      </Box>
    );
  };

  return (
    <div className={classes.searchContainer}>
      <Box>
        <TextField
          placeholder="Search"
          variant="outlined"
          autoComplete="off"
          onClick={handleClick}
          onFocus={handleClick}
          onChange={handleChange}
          value={searchValue}
          fullWidth
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CiSearch style={{ fontSize: "large" }} />
              </InputAdornment>
            ),
            endAdornment: searchValue && (
              <InputAdornment position="end">
                <RxCross2
                  style={{ fontSize: "20px", cursor: "pointer", zIndex: 10 }}
                  onClick={clearSearch}
                />
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 70,
          }}
        />
      </Box>
      {isPopupOpen && searchValue.trim() !== "" && (
        <Box className={classes.popupBox}>
          {loading ? (
            <Box className={classes.noResults}>
              <Typography>Loading...</Typography>
            </Box>
          ) : (
            <>
              {
                // categories.length === 0 &&
                // subcategories.length === 0 &&
                courses.length === 0 ? (
                  <Box className={classes.noResults}>
                    <Typography>No data found.</Typography>
                  </Box>
                ) : (
                  <>
                    {/* {renderSection("Categories", categories, "category")}
                  {renderSection("Subcategories", subcategories, "subcategory")} */}
                    {renderSection("Skill Drops", courses, "course")}
                  </>
                )
              }
            </>
          )}
        </Box>
      )}

      {isPopupOpen && (
        <div
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
          }}
        />
      )}
    </div>
  );
};

export default HeaderSearch;
